@font-face {
  font-family: IranSans;
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/IranSansX/woff/IRANSansXFaNum-Regular.woff'),
  url('../assets/fonts/IranSansX/woff2/IRANSansXFaNum-Regular.woff2') format('woff2');
}
*{
  font-family: Roboto, IranSans;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.home-boxs {
  padding: 0;
  margin: 1rem 0;
  border-radius: 15px;
  border: solid 1px #707070;
  /* background-color: #fa0; */
  background-color: rgb(31, 31, 31);
}
.home-boxs-header {
  /* background-color: #003566; */
  background-color: #0F4DEB;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px 14px 0 0;
}
.home-boxs-header h3 {
  color: #f5f6f6;
  font-weight: normal;
}
.home-boxs-body {
  padding: 1rem;
  color: #d1d1d1;
}
.home-boxs-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: solid 1px #707070;
  background-color: #272727;
  padding: 0.2rem 0.5rem;
  margin: 0.3rem 0;
}
.home-extend-items {
  border-radius: 12px;
  border: solid 1px #707070;
  background-color: #272727;
  margin: 0.5rem 0;
}
.home-boxs-items a,
.extend-text a {
  color: #f5f6f6;
  font-weight: bold;
}
.home-boxs-items span {
  color: #707070;
  border: 1px solid #707070;
  border-radius: 50%;
  padding: 0.2rem;
}
.extend-text {
  font-size: 0.87rem;
}
.small-text {
  font-size: 0.7rem;
}
.app-layout,
.app-layout-auth {
  background-color: #272727;
}
.app-layout-profile {
  background-color: rgb(56, 56, 56);
}

.app-layout-auth h1,
.app-layout-auth p,
.app-layout-auth span,
.app-layout-profile label,
.app-layout-profile h2,
.app-layout-profile p,
.app-layout-profile li {
  color: #d1d1d1;
}

.ant-statistic-content {
  font-size: 15px !important;
}

.active-text-muted {
  color:#72849ae6;
}

.expired-text-muted {
  color:#ffaa00ab;
}

.ant-table-tbody {
  background: #272727;
}

.ml-10 {
  margin-left: 7.5%!important;
}

.mr-10 {
  margin-right: 7.5%!important;
}